<template>
  <div>
    <div class="inline-block px-4 py-1 bg-red-600 bg-opacity-25
    border border-solid border-red-600 transform skew-x-20">
      <p class="text-sm transform -skew-x-20">
        <span class="uppercase text-red-600 mr-2 font-semibold">Error</span>
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    /**
     * The error message to be displayed
     */
    error: {
      default: '',
    },
  },
};
</script>
