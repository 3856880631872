<template>
  <transition name="slide-down" mode="out-in">
    <div class="pt-12">
      <div class="flex justify-center px-6 mb-10">
        <Logo class="h-8" />
      </div>
      <div class="absolute left-0 right-0 flex justify-center items-center z-10 mt-0">
        <div class="
          border-grey-100 border-2 bg-gray-900 py-1 px-5 transform skew-x-20
        ">
          <h2 class="text-white uppercase text-2xl font-medium transform -skew-x-20">
            Sign Up For Orbital
          </h2>
        </div>
      </div>
      <div
        class="border border-gray-500 mx-auto mt-16 w-11/12 sm:w-6/12 lg:w-1/2 mb-12 px-12 py-10"
        key="form">
        <div class="flex flex-col mb-4 text-gray-200 text-sm">
          <p>
            <!-- eslint-disable-next-line -->
            Enter your organization details and domains you'd like to run Orbital against. Your organization account will remain inactive until the Orbital team reviews and approves your requested domain(s).
          </p>
          <p class="font-semibold">
            Note: Enrollment may take up to 3 business days due to a high volume of signup requests.
          </p>
        </div>
        <div class="mb-2">
          <TextInput id="org-name"
                     type="text"
                     label="Organization Name"
                     placeholder="Organization name..."
                     v-model="name"
                     :errors="fieldErrors.name"
                     @keyup.enter.native="handleSignUp"/>
        </div>
        <div class="mb-8">
          <Textarea id="whitelist"
                     type="text"
                     label="Requested domain(s)"
                     placeholder="Enter comma-separated domain(s) to run Orbital reports against"
                     v-model="whitelist"
                     :errors="fieldErrors.whitelist"
                     @keyup.enter.native="handleSignUp"/>
          <p class="text-sm">
            Enter a comma-separated list of domains you'd like to run reports against.
          </p>
          <p class="italic text-gray-300">Example: test.com,test.co.uk,testing.com</p>
        </div>
        <div class="mb-2 grid grid-cols-2 gap-4">
          <TextInput id="first-name"
                     type="text"
                     label="First Name"
                     placeholder="First name..."
                     v-model="adminFirstName"
                     :errors="fieldErrors.adminFirstName"
                     @keyup.enter.native="handleSignUp"/>
          <TextInput id="last-name"
                     type="text"
                     label="Last Name"
                     placeholder="Last name..."
                     v-model="adminLastName"
                     :errors="fieldErrors.adminLastName"
                     @keyup.enter.native="handleSignUp"/>
        </div>
        <div class="mb-2">
          <TextInput id="email"
                     type="email"
                     label="Email"
                     placeholder="Email address..."
                     v-model="adminEmail"
                     :errors="fieldErrors.adminEmail"
                     @keyup.enter.native="handleSignUp"/>
        </div>
        <div class="mb-8">
          <TextInput id="password"
                     type="password"
                     label="Password"
                     placeholder="Create a password..."
                     v-model="adminPassword"
                     :errors="fieldErrors.adminPassword"
                     @keyup.enter.native="handleSignUp"/>
        </div>
        <vue-hcaptcha
          v-if="!isLocal"
          @verify="onVerifyCaptcha"
          :sitekey="H_CAPTCH_KEY"
          theme="dark"></vue-hcaptcha>
        <Button
          :disabled='captchaIncompleteOrFailed'
          :inFlight="isLoading"
          type="primary"
          class="mt-4"
          @onClick="handleSignUp">
          Submit
        </Button>
        <div v-if="globalErrors.length" class="mt-8">
          <ErrorMessage
            v-for="(error, index) in globalErrors"
            :key="`error${index}`"
            :error="error">
          </ErrorMessage>
        </div>
      </div>

      <div class="border border-gray-500 mx-auto
      w-11/12 sm:w-6/12 lg:w-1/2 mb-12 px-6 py-3 text-center">
        <p class="text-sm">Already have an account?</p>
        <router-link
          :to="{ name: 'login' }"
          class="text-gray-200 text-sm uppercase outline-none
               hover:text-white focus:text-white
               transition-colors duration-200 ease-in">
          Login
        </router-link>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { constants } from '@/config';
import Button from '@/components/Button.vue';
import Logo from '@/assets/orbital_logo.svg?inline';
import TextInput from '@/components/TextInput.vue';
import Textarea from '@/components/Textarea.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

const { H_CAPTCH_KEY } = constants;

export default {
  name: 'Register',
  components: {
    Button,
    ErrorMessage,
    Logo,
    Textarea,
    TextInput,
    VueHcaptcha,
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'globalErrors',
      'fieldErrors',
    ]),
    incompleteForm() {
      return !this.name || !this.whitelist || !this.adminFirstName
        || !this.adminLastName || !this.adminEmail || !this.adminPassword;
    },
  },
  data() {
    return {
      whitelist: null,
      name: null,
      adminFirstName: null,
      adminLastName: null,
      adminEmail: null,
      adminPassword: null,
      captchaIncompleteOrFailed: window.location.hostname !== 'localhost',
      isLocal: window.location.hostname === 'localhost',
      H_CAPTCH_KEY,
    };
  },
  beforeMount() {
    localStorage.clear();
  },
  created() {
    this.clearGlobalErrors();
    this.clearFieldErrors();
  },
  methods: {
    ...mapActions([
      'clearGlobalErrors',
      'clearFieldErrors',
      'signup',
    ]),
    onVerifyCaptcha() {
      this.captchaIncompleteOrFailed = false;
    },
    handleSignUp() {
      const {
        whitelist, name, adminFirstName, adminLastName, adminEmail, adminPassword,
      } = this;

      let whitelistArray = [];

      if (whitelist) whitelistArray = whitelist.split(/[\s,]+/);
      const domain = whitelistArray[0];

      const signupRequest = {
        domain,
        whitelist: whitelistArray,
        name,
        adminFirstName,
        adminLastName,
        adminEmail,
        adminPassword,
      };

      this.signup(signupRequest);
    },
  },
};
</script>
<style scoped>
  .slide-down-enter-active, slide-down-leave-active {
    transition: all .2s ease-in-out;
  }

  .slide-down-enter, .slide-down-leave-to {
    transform: translateY(-10px);
    opacity: 0;
  }
</style>
