<template>
  <div class="relative text-left">
    <label v-if="label"
           class="font-semibold text-xs tracking-widest uppercase
                  transition-colors duration-200 ease-out"
           :class="[
             {'text-gray-200': !focused},
             {'text-white': focused},
             {'text-red-500': errors && errors.length},
           ]"
           :for="id">
      {{ label }}
    </label>
    <div class="input-wrapper"
         :class="[
           {'input-wrapper--focused': focused},
           {'input-wrapper--black': hasBlackCorner}
         ]">
      <textarea
        autocomplete="disabled"
        autocorrect="false"
        spellcheck="false"
        @input="updateValue()"
        @focus="focused = true"
        @blur="focused = false"
        :id="id"
        :name="name"
        :type="type"
        ref="input"
        class="input w-full text-white bg-gray-800 text-base placeholder-gray-400 px-4 py-3
               border-b border-gray-400
               focus:outline-none"
        :class="[
            {'input--filled': value && value.length > 0},
            {'disabled': disabled},
            {'border-red-600': errors && errors.length},
          ]"
        :value="value"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :disabled="disabled"/>
    </div>

    <div v-if="errors && errors.length">
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="text-red-500 text-sm pl-1">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Textarea',
  data() {
    return {
      focused: false,
    };
  },
  props: {
    /**
     * id of the input
     */
    id: {
      type: String,
    },
    /**
     * name of the input
     */
    name: {
      type: String,
    },
    /**
     * Placeholder text inside the input
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Input type. Can be changed to password or email, for instance
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * The value for the text input
     */
    value: {
      type: String,
    },
    /**
     * The text that will be displayed for the Label
     */
    label: {
      type: String,
    },
    /**
     * Determines whether a field is invalid and should display differently (e.g. a red border)
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * The max number of characters of the input field
     */
    maxLength: {
      type: Number,
      default: 524288, // The HTML spec default value
    },
    /**
     * Determines if the field is disabled or not
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Flag specifically for text inputs with icons that should show the label too
     */
    showLabel: {
      type: Boolean,
      default: false,
    },
    /**
     * Changes color of triangle in corner of input to black
     */
    hasBlackCorner: {
      type: Boolean,
      default: false,
    },
    /**
     * Displays error messages on the field
     */
    errors: {
      type: Array,
    },
  },
  methods: {
    updateValue() {
      if (this.$refs.input) {
        this.$emit('input', this.$refs.input.value);
      }
    },
  },
};
</script>
<style scoped>
.input-wrapper {
  @apply relative;

&:before {
   @apply absolute right-0 top-0 border-8 border-gray-900 w-4 h-4;
   border-bottom-color: transparent;
   border-left-color: transparent;
   content: '';
 }

&:after {
   @apply absolute w-full right-0 h-px bg-blue-100 opacity-100 z-20 shadow-glowblue100;
   bottom: 6px;
   transform: scale(0);
   content: '';

   transition: transform 0.2s ease-out;
 }
}

.input-wrapper--black {
&:before {
   @apply border-black;
   border-bottom-color: transparent;
   border-left-color: transparent;
   content: '';
 }
}

.input-wrapper--focused {
&:after {
   transform: scale(1);
 }
}
</style>
